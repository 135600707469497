// Size to Fit textarea behavior
//
// Auto sizes any textareas marked with `.js-size-to-fit` to its text
// contents height.

// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {isFeatureEnabled} from '@github-ui/feature-flags'
import subscribe from '@github/textarea-autosize'

type CSSStyleDeclarationWithFieldSizing = CSSStyleDeclaration & {
  fieldSizing?: 'content' | 'fixed'
}

observe('textarea.js-size-to-fit', {
  constructor: HTMLTextAreaElement,
  subscribe(el) {
    if (isFeatureEnabled('textarea_field_sizing') && CSS?.supports?.('field-sizing', 'content')) {
      ;(el.style as CSSStyleDeclarationWithFieldSizing).fieldSizing = 'content'
      return {
        unsubscribe() {},
      }
    } else {
      return subscribe(el)
    }
  },
})
